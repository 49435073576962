@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$font: (
    "family": "Roboto",
    "weight": 300
);

body {
    margin: 0;
    font-family: map-get($font, "family") !important;
    font-weight: map-get($font, "weight") !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$primary: #5e1604;
$secondary: #38475d;
// $secondary: #5e6052;
$tertiary: #9a9d64;
$tertiary-subtle: #afb27d;
$light: #d9d9d9;

$theme-colors: map-merge($theme-colors, ("primary": $primary,
            "secondary": $secondary,
            "tertiary": $tertiary,
            "tertiary-subtle": $tertiary-subtle,
            "light": $light ));


@import "~bootstrap/scss/bootstrap";

#footer {
    color: $light;
}

#divider {
    color: white
}

.card-description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}

.card-description::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 1.1em;
    background: linear-gradient(to bottom, transparent, white 85%);
}

#home-poster {
    background-position: center;
    background-size: cover;
    background-attachment: scroll;
    width: 100%;
    min-height: 200px;
    opacity: 0.55;
}

#delete-content-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px 10px;
    background-color: red;
    color: white;
    border: none;
    opacity: 0.8;
}

label.required::after {
    content: " *";
    color: red;
}

@media (max-width:450px) {
    .container-lg button.btn {
        width: auto !important;
    }

    .row .col {
        padding-right: 0;
    }
}

@media (min-width: 768px) {
    #home-poster {
        min-height: 65vh;
        background-attachment: fixed;
    }
}

@import "bootstrap";